<template>
  <div ref="spot" class="product-spot mb-40">
    <div
      v-if="product"
      class="product-image-container flex flex-col bg-grey400 bg-cover bg-center mb-16 px-24 pt-40"
      :class="{
        'pb-48 h-300 sm:h-360': fullWidthImage,
        'pb-48 h-auto sm:h-360': !fullWidthImage && !showCta,
        'product-image-container--show-cta pb-40': showCta,
      }"
      :style="bgStyles"
    >
      <nuxt-link
        v-if="!isSample"
        :to="product?.url"
        class="h-full"
        @click.native="productClick"
      >
        <div
          class="h-full w-full flex justify-center items-center mx-auto relative"
          :class="{ 'max-h-240 max-w-320': !fullWidthImage }"
        >
          <img
            v-if="
              productImage.shouldShowShadow &&
              productImage.image &&
              !isBundleProduct &&
              !fullWidthImage &&
              product?.showCanShadow &&
              !gifImage
            "
            src="~/assets/images/can-shadow.png"
            class="can-image"
            height="240"
            width="340"
          />
          <nuxt-img
            v-if="!fullWidthImage"
            :src="gifImage ? gifImage[0]?.url : productImage.image"
            :preset="gifImage ? '' : 'productImage'"
            loading="lazy"
            class="product-image z-10 max-h-240 max-w-10/12 sm:max-w-240 object-contain"
            height="240"
            width="240"
            :alt="product?.name"
          />
          <img
            v-if="pouchImage && productImage.image && !fullWidthImage && !gifImage"
            :src="pouchImage"
            class="pouch-image"
          />
        </div>
        <div
          v-if="showCta"
          :to="product.url"
          class="flex-shrink-0 btn btn--md base-medium-text btn--dark-theme btn--wide mt-32"
          :is-block="false"
          :size="Sizes.md"
        >
          <span>{{ $lang('productListingResources', 'goToProduct') }}</span>
        </div>
      </nuxt-link>
      <div v-else class="h-full">
        <div
          class="max-h-240 max-w-320 h-full w-full flex justify-center items-center mx-auto relative"
        >
          <img
            v-if="
              productImage.shouldShowShadow &&
              productImage.image &&
              !isBundleProduct &&
              product.showCanShadow
            "
            src="~/assets/images/can-shadow.png"
            class="can-image"
          />
          <img
            :src="productImage.image"
            class="product-image max-h-240 max-w-10/12 sm:max-w-240 z-10 object-contain"
            height="240"
            width="240"
          />
          <img
            v-if="pouchImage && productImage.image"
            :src="pouchImage"
            class="pouch-image"
          />
        </div>
      </div>
    </div>
    <div v-if="showInfo" class="product-info" :style="`color: ${textColor}`">
      <div class="flex flex-col flex-1">
        <nuxt-link v-if="!isSample" :to="product.url">
          <div class="flex justify-between">
            <div class="base-medium-text">{{ product.name }}</div>
            <div
              v-if="purchaseEnabled"
              class="px-16 flex-shrink-0 mt-2 hidden md:block"
            >
              <div v-if="selectedVariant">
                <div
                  class="base-medium-text"
                  :class="{ 'text-error': selectedVariant.hasDiscount }"
                >
                  {{ selectedVariant.price
                  }}<span v-if="selectedVariant.packageSize > 1"
                    >/{{ selectedVariant.packageSize }}x</span
                  >
                  <span
                    v-if="
                      selectedVariant.hasDiscount &&
                      selectedVariant.recommendedPrice
                    "
                    class="small-base-text text-grey600 line-through font-normal ml-4"
                    >({{ selectedVariant.recommendedPrice }})</span
                  >
                </div>
              </div>
            </div>
          </div>
        </nuxt-link>
        <div v-else class="flex justify-between">
          <div class="base-medium-text">{{ product.name }}</div>
          <div
            v-if="purchaseEnabled"
            class="px-16 flex-shrink-0 mt-2 hidden md:block"
          >
            <div v-if="selectedVariant">
              <div
                class="small-base-text"
                :class="{ 'text-error': selectedVariant.hasDiscount }"
              >
                {{ selectedVariant.price
                }}<span v-if="selectedVariant.packageSize > 1"
                  >/{{ selectedVariant.packageSize }}x</span
                >
                <span
                  v-if="
                    selectedVariant.hasDiscount &&
                    selectedVariant.recommendedPrice
                  "
                  class="small-base-text text-grey600 line-through font-normal ml-4"
                  >({{ selectedVariant.recommendedPrice }})</span
                >
              </div>
            </div>
          </div>
          <div
            v-else-if="enableGlobalAgeVerification"
            class="px-16 flex-shrink-0 mt-2 hidden md:block"
          >
            <div class="small-base-text text-error font-medium text-base">
              {{ selectedVariant?.price }}
            </div>
          </div>
        </div>
        <div
          v-if="product.description"
          class="small-base-text text-grey600 mt-4"
        >
          {{ product.description }}

          <div
            v-if="selectedVariant && purchaseEnabled"
            class="md:hidden mt-12"
          >
            <div
              class="base-medium-text text-grey800"
              :class="{ 'text-error': selectedVariant.hasDiscount }"
            >
              {{ selectedVariant.price
              }}<span v-if="selectedVariant.packageSize > 1"
                >/{{ selectedVariant.packageSize }}x</span
              >
              <span
                v-if="
                  selectedVariant.hasDiscount &&
                  selectedVariant.recommendedPrice
                "
                class="small-base-text text-grey600 line-through font-normal ml-4"
                >({{ selectedVariant.recommendedPrice }})</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="px-16 sm:px-0 w-full sm:w-auto mt-auto">
      <Button
        v-if="isSample"
        class="text-xs sm:text-sm md:mt-24 mt-12 px-4 sm:w-auto sm:px-32 sample-button"
        :class="{ loading: isLoading }"
        :is-block="false"
        :size="Sizes.md"
        :text="
          selectedVariant?.inStock
            ? $lang('sampleResources', 'sampleAddToCart')
            : $lang('productListingResources', 'outOfStock')
        "
        :disabled="!selectedVariant?.inStock"
        :loading="isLoading"
        @click="onSamplePurchase"
      />
    </div>
    <div class="absolute top-24 left-24 z-20">
      <div
        v-for="flag in product.flags"
        :key="`productSpotFlag-${flag.name}-${product.id}`"
        class="px-12 py-8 mr-12 last:mr-0 mb-12 text-grey200 font-semibold text-sm w-fit-content"
        :style="`background-color: ${
          flag.code !== 'thunder-new' ? flag.color : 'transparent'
        }`"
        :class="{ hidden: flag.isCampaignFlag && isSample }"
      >
        <img
          v-if="flag.code === 'thunder-new'"
          class="h-36 -mt-8 -ml-8 md:ml-0"
          src="~/assets/images/thunder-new-flag.png"
        />
        <span v-else>{{ flag.name }}</span>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useGlobalContentStore } from '~/store/globalContent';
import { useCartStore } from '~/store/cart';
import { useUserStore } from '~/store/user';
import Button from './globals/Button.vue';
import { Sizes, Themes } from '~/constants/button-layouts';
import {
  type IProductItem,
  PurchaseType,
  type IProductVariant
} from '~/models/api-types';
import * as Sentry from '@sentry/vue';
import { useVoyadoStore } from '~/store/voyado';

const config = useGlobalContentStore().config;
const cartStore = useCartStore();
const userStore = useUserStore();
const { $lang } = useNuxtApp();
const route = useRoute();
const globalContentStore = useGlobalContentStore();
const voyado = useVoyadoStore();
const { lastError } = useApiFetch();

interface IProps {
  product: IProductItem,
  isMobile?: boolean,
  useSchemaProps?: boolean,
  subscription?: boolean,
  listName?: string,
  index?: number,
  fullWidthImage?: boolean,
  isSample: boolean,
  showInfo?: boolean,
  showCta?: boolean,
  textColor?: string
}

const props = withDefaults(defineProps<IProps>(), {
  useSchemaProps: true,
  showInfo: true,
  showCta: false,
  textColor: '',
  isSample: false
});

const selectedVariantId = ref('');
const isLoading = ref(false);
const spot = ref();

const selectedVariant = computed(() => {
  return (
    props.product.variants.find(
      (variant) => variant.partNo === selectedVariantId.value
    ) || null
  );
});

const pouchImage = computed(() => {
  return props.product.pouchImageUrl;
});

const gifImage = computed(() => {
  if (!config.currentMarket?.showGifImagesOnProductListingPage) return false;

  const gifImage = props.product.additionalImages?.filter((image) => {
    return image?.code == 'gif';
  });

  if (gifImage.length) return gifImage;

  return false;
});

const productImage = computed((): { image: string; shouldShowShadow: boolean } => {
  const variantIndex = props.product.variants
    .map((variant) => variant.partNo)
    .indexOf(selectedVariantId.value);

  return selectedVariant.value?.imageUrl
    ? {
        image: `${selectedVariant.value?.imageUrl}?w=240&h=240`,
        shouldShowShadow: variantIndex <= 0 && props.product.showCanShadow,
      }
    : {
        image: `${props.product.imageUrl}?w=240&h=240`,
        shouldShowShadow: true,
      };
});

const bgStyles = computed(() => {
  return props.fullWidthImage
    ? {
        'background-image': `url(${productImage.value.image})`,
      }
    : {};
});

const isBundleProduct = computed(() => {
  return (
    selectedVariant.value?.productType === 'ManagedStructure' ||
    selectedVariant.value?.productType === 'ManagedErpPPackage' ||
    selectedVariant.value?.productType === 'ManagedErpPackageWithCalc'
  );
});

const purchaseEnabled = computed(() => {
  return config.currentMarket.enablePurchases;
});

const ageVerificationPageUrl = computed(() => {
  return config.currentMarket?.globalAgeVerificationPageUrl || '/';
});

const enableGlobalAgeVerification = computed(() => {
  return config.currentMarket?.globalAgeVerificationPageUrl;
});

const isAgeVerified = computed(() => {
  return userStore.isVerified;
});

onBeforeMount(() => {
  const numberOfVariants = props.product.variants.length;
    if (
      numberOfVariants > props.product.standardProductVariant - 1 &&
      props.product.variants[props.product.standardProductVariant - 1].inStock
    ) {
      selectedVariantId.value =
        props.product.variants[props.product.standardProductVariant - 1].partNo;
    } else {
      let currentIndex = numberOfVariants - 1;
      let foundStandard = false;
      do {
        if (currentIndex === 0) {
          selectedVariantId.value = props.product.variants[currentIndex].partNo;
          foundStandard = true;
        } else if (props.product.variants[currentIndex].inStock) {
          selectedVariantId.value = props.product.variants[currentIndex].partNo;
          foundStandard = true;
        } else {
          currentIndex--;
        }
      } while (!foundStandard);
    }
});

onMounted (async() => {
  if (config.currentMarket?.enableGlobalAgeVerification && config.isLoggedIn) {
    await userStore.checkIsVerified();
  };
  nextTick(() => {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0) {
            window.dataLayer?.push({
              event: 'productImpressions',
              ecommerce: {
                currencyCode: selectedVariant.value?.currencyCode,
                impressions: [
                  {
                    list: props.listName ?? route.path,
                    ...selectedVariant.value?.gtmProperties,
                    variant: '1-pack',
                  },
                ],
              },
            });
            if (selectedVariant.value) {
              voyado.sendProductView(selectedVariant.value);
            }
            if (spot.value) {
              observer.unobserve(spot.value as Element);
            }
          }
        });
      },
      { rootMargin: '0px', threshold: 0.5, root: null }
    );
    if (spot.value) {
      observer.observe(spot.value as Element);
    }
  });
});

const productClick = () => {
  window.dataLayer?.push({
    event: 'productClick',
    ecommerce: {
      currencyCode: selectedVariant.value?.currencyCode,
      click: {
        actionField: {
          list: props.listName ?? route.path,
        },
        products: [
          {
            position: props.index,
            ...selectedVariant.value?.gtmProperties,
            variant: '1-pack',
          },
        ],
      },
    },
  });
};

const router = useRouter();
const onSamplePurchase = async() => {
  if (selectedVariant.value) {
    try {
      isLoading.value = true;
      await cartStore.addItemToCart({
        item: selectedVariant.value,
        purchaseType: PurchaseType.Sampling,
        hidePopup: false,
      });
      
      if (!lastError.value){
        if (
          config.currentMarket?.enableGlobalAgeVerification &&
          !isAgeVerified.value
        ) {
          window.location.href = `${ageVerificationPageUrl.value}?purchaseType=${PurchaseType.Sampling}` || '/';
        } else {
          window.location.href = `${config.currentMarket?.checkoutUrl}?purchaseType=${PurchaseType.Sampling}` || '/';
        }
      }
    } catch (e) {
      Sentry.captureException(e);
    } finally {
      isLoading.value = false;
    }
  }
};

const product = props.product as IProductItem | null;
const selectedVariantValue = selectedVariant.value as IProductVariant | null;
const host = globalContentStore.host;

const jsonLd = {
  '@context': 'https://schema.org',
  '@type': 'Product',
  gtin: product?.ean,
  name: product?.name,
  description: product?.description,
  sku: selectedVariantValue?.gtmProperties.id,
  image: product?.imageUrl,
  brand: {
    '@type': 'Brand',
    name: selectedVariantValue?.gtmProperties.brand,
  },
  url: host + (product?.url ?? ''),
  offers: {
    '@type': 'Offer',
    priceCurrency: selectedVariantValue?.currencyCode,
    price: selectedVariantValue?.gtmProperties?.price
      ?.toString()
      ?.replace(',', '.'),
    availability: selectedVariantValue?.inStock
      ? 'https://schema.org/InStock'
      : 'https://schema.org/OutOfStock',
  },
} as any;

if (props.useSchemaProps) {
  useJsonld(jsonLd);
}
</script>
<style scoped>
.product-spot {
  &--list,
  &--slider {
    position: relative;
    width: calc(50% - 0.25rem);

    @screen sm {
      width: calc(50% - 1rem);
    }
    @screen md {
      width: calc(33.333% - 1rem);
    }

    .can-image {
      position: absolute;
      right: 0;
      left: 0;
      margin: 0 auto;
      height: 100%;
      width: 100%;
      bottom: -16px;
      @screen sm {
        bottom: -33px;
      }
    }

    &:nth-child(odd) {
      @apply mr-8 sm:mr-24 md:mr-0;
    }

    &:not(:nth-child(3n)) {
      @apply md:mr-24;
    }

    .product-image-container {
      height: auto;
      @apply px-16 pt-32 pb-36 sm:pt-40 sm:h-360 w-full sm:px-24;

      &--show-cta {
        height: 24.5rem;

        @media screen and (max-width: 600px) {
          height: 24rem;
        }
      }
    }

    .product-image {
      @apply max-h-full sm:max-h-240;
    }

    .product-info {
      @apply block px-16 sm:flex sm:px-0 w-full;
    }

    .base-medium-text {
      @apply text-sm leading-sm sm:text-base sm:leading-base;
    }

    .pouch-image {
      bottom: -18px;
      right: -15px;
      @apply max-h-112 z-20 absolute sm:max-h-112 sm:right-0 sm:z-20;

      @screen sm {
        bottom: -41px;
      }
    }
  }

  &--slider {
    @apply w-full px-12 mb-0 sm:mb-40;

    .can-image {
      bottom: -33px;
    }

    .pouch-image {
      bottom: -41px;
    }
  }

  &--brand-image {
    .product-image-container {
      min-height: 310px;
    }
  }
}
</style>
<style>
.product-spot.product-spot--list {
  @apply flex flex-wrap;
  .sample-button {
    font-size: 15px;
    @apply sm:text-sm;

    &.loading {
      .text {
        @apply hidden sm:inline-block;
      }

      div {
        @apply m-0 sm:ml-12;
      }
    }
  }
}
</style>
